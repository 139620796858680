<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <div v-for="(inner, j) in item" :key="j">
        <input type="text" v-model="inner.word" placeholder="Word" />
        <input type="text" v-model="inner.src" placeholder="Audio link" />
        <input type="text" v-model="inner.image" placeholder="Image link" />
      </div>

      <button type="button" @click="addInner(item)">
        + Add
      </button>

      <button type="button" @click="copyItem(item)" class="ml-10">
        ][ Copy
      </button>
    </div>

    <hr v-if="letterWords.length > 0" />

    <button type="button" @click="addItem">
      + Add Item
    </button>
  </form>
</template>

<script>
// "letterWords": [
// [
// {
//   "word": "airplane",
//   "src": "type-9-c/airplane.mp3",
//   "image": "type-9-c/airplane.svg"
// },
// ]
// ]

export default {
  name: 'Type9c',
  data() {
    return {
      letterWords: [
        [
          {
            word: '',
            src: '',
            image: '',
          },
        ],
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      let result = JSON.parse(JSON.stringify(this.letterWords));

      return result;
    },
  },
  methods: {
    addItem() {
      this.letterWords.push([
        {
          word: '',
          src: '',
          image: '',
        },
      ]);
    },
    addInner(inner) {
      inner.push({
        word: '',
        src: '',
        image: '',
      });
    },
    copyItem(item) {
      item.push(Object.assign({}, item[item.length - 1]));
      this.$emit('input', this.formattedLetterWords);
    },
  },
};
</script>
